import React, { useState, useEffect, useLayoutEffect } from 'react';
import { DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import Cookies from 'js-cookie';
import NP from 'number-precision';
import { Tooltip, Dropdown, Badge } from 'antd';
import { Message, Icon } from '@/components';
import { ProLayout } from '@ant-design/pro-components';
import { useLocation, Outlet } from 'react-router-dom';
import defaultSettings from '@/const/defaultSettings';
import { AccountStore, CommonStore, PageCacheStore, FitmentStore } from '@/stores';
import HeaderMenu from './HeaderMenu';
import SiderMenu from './SiderMenu';
import TabMenu from './TabMenu';
import AuthContainer from './AuthContainer';
import HeaderLeftContent from './HeaderLeftContent';
import TabLink from '@/components/TabLink';
// import AvatarPopover from './AvatarPopover';
import NoticeDrawer from './NoticeDrawer';
import { getFlatMenus, formartMenu } from '@/tools/getFlatMenus';
import { ApplicationInfo, hrefLogos } from '@/const/application';
import getMatchMenu from '@/tools/getMatchMenu';
import { pptxUploadQueue } from '@/pages/microApp';
import type { UserInfo } from '@/stores/account';
import type { MenuDataItem } from '@/const/route.config';
import { parseQueryString, toLink } from '@/tools/common';
import { menus, adminMenus } from './menus';

import './index.less';

import { CommonHeader, CommonFooter } from '@/fitmentComponents';

const viewComponentMap = new Map([
  ['view__common__header', CommonHeader],
  ['view__common__footer', CommonFooter],
]);

export interface AuthorizedModuleType {
  authorizedCount: number;
  moduleId: string | number;
  moduleName: string;
  status: 0 | 1;
  totalGrantNum: number;
}
const loginUrl = '/account/login';
const HasToken = Cookies.get('token')?.includes('99-1') ? null : Cookies.get('token');
const miaohuoToken = Cookies.get('miaohuoToken');
// 纯净模式展示的路由
const pureRoute = new Map([['/login', true]]);

// 不展示菜单的页面
const noMenuPage = [
  '/',
  '/module/showroom/mall',
  '/module/showroom/cart',
  '/module/showroom/search',
];

const { systemId, title } = ApplicationInfo;
const BasicLayout: React.FC = () => {
  const location = useLocation();

  const { type = '' } = parseQueryString() as any;
  const { key = '' } = parseQueryString() as any;
  const [headerTemplate, setHeaderTemplate] = useState({});
  const [footerTemplate, setFooterTemplate] = useState({});
  const [linkTitle, setLinkTitle] = useState<any>(null);
  const [textLogo, setTextLogo] = useState<any>(null);
  const [menu, setMenu] = useState<MenuDataItem[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [authModule, setAuthModule] = useState<AuthorizedModuleType[]>(); // 权限模块

  // 扁平化菜单，用于搜索，避免频繁计算
  const [flatMenus, setFlatMenus] = useState<Record<string, MenuDataItem> | null>(null);

  const handleHasToken = async () => {
    if (!miaohuoToken) {
      const __menu = formartMenu(menus, [linkTitle || title]);
      setMenu(__menu);

      const __flatMenus = __menu.length ? getFlatMenus(__menu) : null;
      setFlatMenus(__flatMenus);
    } else {
      AccountStore.getUserInfo().then((data) => {
        setUserInfo(data);
        window.localStorage.setItem('userInfo', JSON.stringify(data));
        const newMenu = data?.adminFlag === 1 ? adminMenus : menus;
        const __menu = formartMenu(newMenu, [title]);
        setMenu(__menu);

        const __flatMenus = __menu.length ? getFlatMenus(__menu) : null;
        setFlatMenus(__flatMenus);
      });
    }
  };
  useLayoutEffect(() => {
    const tokenType =
      window.location.origin.indexOf('mall.wowcarp.com') >= 0
        ? !!Cookies.get('miaohuoToken') && !!Cookies.get('token')
        : !!Cookies.get('token');
    if (
      tokenType ||
      location.pathname.indexOf('/showroom/detail') >= 0 ||
      noMenuPage?.includes(location.pathname)
    ) {
      handleHasToken();
    } else {
      const loginUrl = `/account/login?redirectUrl=${encodeURIComponent(window.location.href)}`;
      window.location.hostname !== 'localhost' && window.location.replace(loginUrl);
    }
  }, []);

  const [matchMenu, setMatchMenu] = useState<MenuDataItem[]>([]);
  const [currentMenu, setCurrentMenu] = useState<MenuDataItem>();
  useEffect(() => {
    // 切换页面时，重置滚动条
    window.scrollTo(0, 0);

    if (flatMenus && location.pathname) {
      const pathname =
        location.pathname === '/redirectPage' ? location.state?.path : location.pathname;

      const _matchMenu = getMatchMenu(pathname, flatMenus, true, true);
      const checkMenu = _matchMenu?.[_matchMenu.length - 1];

      CommonStore.updateState({
        currentMenu: checkMenu,
      });
      setMatchMenu(_matchMenu);
      setCurrentMenu({ ...checkMenu });

      if (checkMenu) {
        const { singleTab } = JSON.parse(decodeURIComponent(checkMenu.props || '{}'));

        const path = `${location.pathname}${location.search}`;
        const key = `${location.pathname}${singleTab ? '' : location.search}`;
        const name = checkMenu.name || '标签页';

        PageCacheStore.updatePageTab({
          key,
          path,
          name,
          singleTab,
        });
      }
    }
  }, [flatMenus, location]);

  // 判断用户是否改变
  // useEffect(() => {
  //   document.addEventListener('visibilitychange', () => {
  //     if (document.visibilityState === 'visible') {
  //       AccountStore.checkUserId().then((data: any) => {
  //         if (!data) {
  //           window.location.replace('/module/workbench/dashboard');
  //         }
  //       });
  //     } else {
  //       // 当前标签页变为不可见状态
  //       // 执行相应的操作
  //     }
  //   });
  // }, []);

  const getOperatePageInfo = async () => {
    try {
      let getId;
      if (!HasToken) {
        const res = await FitmentStore.getOutHomeIndexId({
          application: 5,
        });
        if (res?.success) {
          getId = res?.data.id;
          localStorage.setItem('uniqueKey', res?.data.uniqueKey);
        }
      } else {
        const { success, data } = await FitmentStore.getHomeIndexId({ application: 5 });
        getId = data;
      }
      if (!getId) return;
      const params = {
        id: getId,
      };
      const res = await FitmentStore.getPageInfo(params);
      if (res?.code == 2000) {
        let obj: any = {};
        if (type === 'preview') {
          obj = JSON.parse(res?.data?.savedContent || '{}');
        } else {
          obj = JSON.parse(res?.data?.content || '{}');
        }
        obj.templateList?.map((item: any) => {
          if (item.componentId === 'view__common__header') {
            setHeaderTemplate(item);
          }
          if (item.componentId === 'view__common__footer') {
            setFooterTemplate(item);
          }
          return null;
        });
      }
    } catch (error: any) {
      // if (error?.msg === '运营页数据查询失败!') {
      //   setNoPage(true);
      // }
    }
  };
  const setIcon = async () => {
    const location = window.location;
    const link: any = document.getElementById('links');
    const hrefs = hrefLogos();

    if (location.origin.includes('http://locahost')) {
      link.href = hrefs;
    } else {
      const res: any = await CommonStore.loginPageInfo({
        loginLink: location.host + '/account/login',
      });
      if (res) {
        console.log(res);
        link.href = res.backendLogo || hrefs;
        setLinkTitle(res.portalName || null);
        setTextLogo(res.portalLogo || null);
      }
    }
  };
  useEffect(() => {
    if (Cookies.get('token')?.includes('99-1')) {
      Cookies.remove('token');
      Cookies.remove('loginType');
      Cookies.remove('loginLink');
      localStorage.removeItem('token');
    }
    setTimeout(() => {
      getOperatePageInfo();
      setIcon();
    }, 100);
  }, []);

  // 渲染组件
  const renderComponent = (item: any) => {
    if (!item.show) {
      return <div key={item.id} />;
    }
    const IsComponent: any = viewComponentMap.get(item.componentId);
    console.log(IsComponent);
    return IsComponent ? (
      <IsComponent
        {...{
          data: item.data,
          id: item.id,
          handleClickComponent: () => {},
          toLink: (params: any) => {
            if (type === 'preview') return;
            toLink(params);
          },
        }}
        key={item.id}
        from={type}
      />
    ) : null;
  };

  return (
    <>
      {renderComponent(headerTemplate)}
      <ProLayout
        {...defaultSettings}
        siderWidth={180}
        location={location}
        className={
          noMenuPage?.includes(location.pathname) ||
          location.pathname.indexOf('/showroom/detail') >= 0
            ? 'peliten_main_layout'
            : 'peliten__layout'
        }
        logo={textLogo || ApplicationInfo.textLogo}
        title={linkTitle || ApplicationInfo.title}
        style={{
          flexDirection: 'row',
          minHeight: 'calc(100vh - 332px)',
          width:
            noMenuPage?.includes(location.pathname) ||
            location.pathname.indexOf('/showroom/detail') >= 0
              ? '100%'
              : 'calc(100% - 32px)',
          margin:
            noMenuPage?.includes(location.pathname) ||
            location.pathname.indexOf('/showroom/detail') >= 0
              ? '0'
              : '16px',
        }}
        pure={pureRoute.get(location.pathname)} // 是否展示纯净模式
        headerRender={false}
        headerTitleRender={false}
        headerContentRender={false}
        actionsRender={false}
        avatarProps={false}
        menuRender={() =>
          HasToken &&
          !noMenuPage?.includes(location.pathname) &&
          location.pathname.indexOf('/showroom/detail') < 0 && (
            <SiderMenu menuData={menu} flatMenus={flatMenus} currentMenu={currentMenu} />
          )
        }
        footerRender={false}
      >
        <AuthContainer authModule={authModule} matchMenu={matchMenu}>
          <Outlet
            context={{
              userInfo,
              matchMenu,
              currentMenu,
              flatMenus,
            }}
          />
        </AuthContainer>
      </ProLayout>
      {renderComponent(footerTemplate)}

      <Message />
    </>
  );
};

export default observer(BasicLayout);
