import React, { useState } from 'react';
import classnames from 'classnames';
import { Input, Image, Divider } from 'antd';
import { Icon } from '@/components';
import Cookies from 'js-cookie';
import { AccountStore } from '@/stores';
import { pptxUploadQueue } from '@/pages/microApp';
import { parseQueryString, toLink } from '@/tools/common';

import './index.less';

interface MainProps {
  [key: string]: any;
}

const loginUrl = '/account/login';

// 分类广告复合组件
const ClassA: React.FC<MainProps> = (props): JSX.Element => {
  // 键盘enter搜索
  const searchKeywordRef = React.useRef(null);

  const { data, id, handleClickComponent, classNames, componentClose } = props;
  const {
    bgColor,
    marginBottom,
    rightImageList,
    textList,
    classList,
    linkInfo,
    welcomeContent,
    phone,
  } = data;
  const { type = '' } = parseQueryString() as any;

  // 搜索key
  const [searchKeyword, setSearchKeyword] = useState<any>(undefined);

  return (
    <div
      className={classnames({ ...classNames, 'common-header-component ': true })}
      id={id}
      onClick={() => {
        handleClickComponent(id);
      }}
      style={{
        backgroundColor: bgColor,
        marginBottom,
      }}
    >
      <div className="showroom-common-headers">
        <div className="common-header-container">
          <div className="common-header-title">
            {welcomeContent}
            {!Cookies.get('token') && (
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft: 8 }}
                onClick={() => {
                  window.location.href = `https://${window.location.host}/account/login`;
                }}
              >
                请登录
              </span>
            )}
          </div>
          <div className="header-info-container">
            {textList?.map((item: any) => (
              <div>
                <span
                  className="header-info-item"
                  onClick={() => {
                    if (type === 'preview') return;
                    if (!Cookies.get('token')) return;
                    if (!item?.link) return;
                    window.open(item?.link);
                  }}
                >
                  {item.name}
                </span>
                <Divider type="vertical" />
              </div>
            ))}
            <div style={{ color: '#ffffff' }}>客服电话：{phone}</div>
            {Cookies.get('token') && (
              <div
                style={{ color: '#ffffff', marginLeft: 8, cursor: 'pointer' }}
                onClick={() => {
                  Promise.allSettled([AccountStore.logout()]).then((res) => {
                    if (res.filter((item) => item.status === 'fulfilled').length > 0) {
                      ['token', 'miaohuoToken'].forEach(async (item: string) => {
                        await Cookies.remove(item);
                      });
                      pptxUploadQueue.cleanAuth();
                      window.location.href = loginUrl;
                    }
                  });
                }}
              >
                退出登录
              </div>
            )}
          </div>
        </div>
      </div>

      {componentClose}
    </div>
  );
};

export default ClassA;
