import { ProLayoutProps } from '@ant-design/pro-components';

const Settings: ProLayoutProps = {
  navTheme: 'light',
  layout: 'mix',
  contentWidth: 'Fixed',
  fixedHeader: true,
  fixSiderbar: true,
  splitMenus: false,
  colorWeak: false,
  siderMenuType: 'group',
  colorPrimary: '#2c68ff',
  menu: { collapsedShowGroupTitle: false },
  pageTitleRender: false,
  suppressSiderWhenMenuEmpty: false,
  disableMobile: true,
  token: {
    // bgLayout: '#F2F5F9',
    header: {
      colorBgHeader: '#fff',
      heightLayoutHeader: 56,
      colorTextMenu: '#333',
      colorTextMenuSecondary: '#333',
      colorTextMenuSelected: '#2c68ff',
      colorTextRightActionsItem: '#dfdfdf',
      colorBgMenuItemHover: 'transparent',
      colorTextMenuActive: '#2c68ff',
    },
    sider: {
      colorTextMenu: '#666',
      colorMenuBackground: '#fff',
      colorTextMenuSelected: '#2C6BFF',
      colorBgMenuItemSelected: '#f1f6fd',
    },
    pageContainer: {
      paddingBlockPageContainerContent: 16,
      paddingInlinePageContainerContent: 16,
    },
  },
  contentStyle: {
    padding: 0,
  },
};

export default Settings;
