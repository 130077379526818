import { makeAutoObservable, runInAction } from 'mobx';
import request from '@/tools/httpClient';
import { BaseURL, getApplicationUrl, TokenKey } from '@/const/serviceEnv';
import Cookies from 'js-cookie';
import { account } from '@/api';

import { MenuDataItem } from '@/const/route.config';
import type { StateProps } from '@/types/common/store';

const LiguanjiaMemberUrl = getApplicationUrl('liguanjia');

export interface UserInfo {
  [key: string]: any;
  id: string;
  companyId: string;
  name: string;
  adminFlag: number;
  account: string;
  companyName: string;
  logoImgUrl: string | null;
  mobile: string;
  plusFlag?: number; // 权益状态码
  certificationStatus: 0 | 1;
}

// TODO: 后期需要加上对应权益code的文案映射

export interface Company {
  companyName: string;
  certifyStatus: 0 | 1;
  id: string;
  companyId: string;
  name: string;
  joinStatus: 0 | 1;
  authorizeStatus: 1 | 2 | 3;
  isValid?: boolean;
}

interface PermissionQuickItems {
  id: number;
  name: string;
  permissionKey: string;
  resourceType: number;
}

class AccountStore {
  [key: string]: any;
  userId = '';

  userInfo?: Record<string, any>;

  constructor() {
    makeAutoObservable(this);
  }

  getUserInfo = async () => {
    return new Promise((resolve: (value: UserInfo) => void, reject) => {
      try {
        request
          .get(account.memberInfo, {
            headers: {
              authority_token: Cookies.get('miaohuoToken'),
              Authorization: Cookies.get('miaohuoToken'),
            },
          })
          .then(({ data, success }) => {
            if (success && data) {
              this.userId = data.id;
              this.userInfo = data;
              resolve(data);
            }
            reject();
          });
      } catch (ex) {
        reject();
      }
    });
  };

  logout = async () => request.post(account.logout);
  logoutLgj = async () => {
    return request.post(account.logoutOther, {
      baseURL: LiguanjiaMemberUrl,
      headers: {
        Authorization: Cookies.get(TokenKey),
      },
    });
  };

  getTeamHistory = async (memberToken: string) => {
    return new Promise((resolve: (value: UserInfo) => void, reject) => {
      try {
        request
          .post(account.getTeamHistory, null, {
            baseURL: LiguanjiaMemberUrl,
            headers: {
              Authorization: memberToken,
            },
          })
          .then(({ data }) => {
            if (data) {
              this.hasPreviousLevel = data.has_previous_level || false;
              Cookies.set('has_previous_level', data.has_previous_level || false);
              resolve(data);
            }
            reject();
          });
      } catch (ex) {
        reject();
      }
    });
  };

  getCompanyList = async () => {
    return new Promise((resolve: (value: Company[]) => void, reject) => {
      try {
        request.get(account.getCompanyList).then(({ data, success }) => {
          if (success && data) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  changeCompany = async (params: { id: string }) => {
    return new Promise((resolve: (token: string) => void, reject) => {
      try {
        request.post(account.changeCompany, params).then(({ data, success }) => {
          if (success && data.token) {
            resolve(data.token);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 创建企业
  companyCreate = async (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request
          .post(account.createCompany, params)
          .then(({ success, message, data }) => {
            if (success && data) {
              resolve(data.employeeId);
            }
            reject(message);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (ex) {
        reject();
      }
    });
  };

  getTeamInfo = async (params: { company_id: string }) => {
    return request.get(account.getTeamInfo, {
      params,
      baseURL: BaseURL,
      headers: {
        Authorization: Cookies.get('token') || Cookies.get('miaohuoToken'),
      },
    }) as Promise<any>;
  };

  getApplictionPermission = async (params: { systemIds: string[] }) => {
    return new Promise((resolve: (value: Array<{ systemId: string }>) => void, reject) => {
      try {
        request.post(account.getApplictionPermission, params).then(({ data, success }) => {
          if (success && data) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  getMenuPermission = async (params: {
    systemId: string;
    resourceTypeList: number[];
  }): Promise<MenuDataItem[]> => {
    return new Promise((resolve: (value: MenuDataItem[]) => void, reject) => {
      try {
        request.post(account.getMenuPermission, params).then(({ data, success }) => {
          if (success && data) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  getUserPermission = async (params: { permissionKeyList: string[] }) => {
    return new Promise((resolve: (value: PermissionQuickItems[]) => void, reject) => {
      try {
        request.post(account.getUserPermission, params).then(({ data, success }) => {
          if (success && data) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  getSiteId = async () => {
    return new Promise((resolve: (value: any) => void, reject) => {
      try {
        request.get(account.getSiteId).then(({ data, success }) => {
          if (success && data) {
            Cookies.set('siteId', data.siteId);
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取用户的当前权益
  getLicenseInfo = async () => {
    return await request.get('/elebuys/company/rights-batch/rights-info');
  };

  updateState = (params: StateProps) => {
    for (const [key, value] of Object.entries(params)) {
      runInAction(() => {
        this[key] = value;
      });
    }
  };

  // 根据用户ID和最新的token判断是否是同一个人
  checkUserId = async () => {
    return new Promise((resolve: (value: UserInfo) => void, reject) => {
      try {
        request
          .get('/elebuys/user/login/v2/check-userid-token', {
            // baseURL: LiguanjiaMemberUrl,
            params: {
              userId: this.userId,
            },
          })
          .then(({ data, success }) => {
            if (success) {
              resolve(data);
            }
            reject();
          });
      } catch (ex) {
        reject();
      }
    });
  };
}

export default new AccountStore();
