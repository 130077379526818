import qs, { ParsedQs } from 'qs';
import { MenuDataItem } from '@/const/route.config';

/**
 * 获取路径query参数
 */
export const getQuery = (): ParsedQs => qs.parse(window.location.search.substring(1));

/**
 * 金额格式化
 * @param data
 */
type moneyType = number | string;

interface ShowMoneyType {
  (data: moneyType): string;
}
export const showMoney: ShowMoneyType = (data) => (+data / 100).toFixed(2);

export function getMainDomain(): string {
  const { hostname } = window.location;
  const parts = hostname.split('.').reverse();

  if (parts && parts[1]) {
    return `${parts[1]}.${parts[0]}`;
  }

  return hostname;
}

export function clearMenuItem(menusData: MenuDataItem[]): MenuDataItem[] {
  const filterData = [...menusData];

  if (filterData[0]?.type === 'divider') {
    filterData.splice(0, 1);
  }
  return filterData
    .map((item) => {
      const children: MenuDataItem[] = item.routes || [];
      const finalItem = { ...item };

      if (!finalItem.name || finalItem.hideInMenu) {
        return null;
      }
      if (finalItem && finalItem?.routes) {
        if (
          !finalItem.hideChildrenInMenu &&
          children.some((child) => child && child.name && !child.hideInMenu)
        ) {
          return {
            ...item,
            routes: clearMenuItem(children),
          };
        }
        // routes 为空就直接删掉
        delete finalItem.routes;
      }
      return finalItem;
    })
    .filter((item) => item) as MenuDataItem[];
}

/**
 * 格式化url参数
 */
export const parseQueryString = () => {
  const url = window.location.href;
  const regUrl = /^[^\?]+\?([\w\W]+)$/; //eslint-disable-line
  const regPara = /([^&=]+)=([\w\W]*?)(&|$|#)/g;
  const arrUrl = regUrl.exec(url);
  const ret: any = {};
  if (arrUrl && arrUrl[1]) {
    const strPara = arrUrl[1];
    let result;
    do {
      result = regPara.exec(strPara);
      if (result !== null) {
        ret[result[1]] = result[2];
      }
    } while (result !== null);
  }
  return ret;
};

const parseLinkToObjectArray = (data: any) => {
  console.log('data', data);
  const { link, linkName } = data;
  const linkIds = link.split('_').map(Number);
  const linkNames = linkName.replace('分类结果-', '').split('>>'); // 去除固定前缀

  const objectArray = linkIds.map((id: any, index: number) => ({
    id,
    name: linkNames[index],
  }));

  return {
    objectArray,
    lastId: linkIds[linkIds.length - 1], // 获取最后一个 ID
  };
};

const base = `/module/showroom`;

/**
 *
 */
export const toLink = (params: any) => {
  const { linkType, link, linkExtend = undefined, isOpenInNewTab = true } = params;
  if (!linkType || !link) return;

  const target = isOpenInNewTab ? '_blank' : '_self';

  switch (linkType) {
    case '1': // 分类
      if (linkExtend == 1) {
        // 跳转分类
        const { objectArray, lastId } = parseLinkToObjectArray({
          linkName: params.linkName,
          link,
        });
        sessionStorage.setItem('searchClass', JSON.stringify(objectArray));
        window.open(`${base}/search?keyword=&flagCategoryTree=${lastId}`, target);
      }
      if (linkExtend == 2) {
        window.open(`${base}/search?keyword=${link}`, target);
      }
      break;
    case '2': // 已装修页面
      !Cookies.get('token') || isMall()
        ? window.open(`${base}/mall?id=${link}`, target)
        : window.open(`${base}/list?id=${link}`, target);
      break;
    case '3': // 商品
      window.open(`${base}/detail/${link}`, target);
      break;
    case '4': // 系统页面
      window.open(`${base}/${link}`, target);
      break;
    case '5': // 外部链接
      window.open(link);
      break;
    default:
  }
};
