import type { FleAppEnv, EnvURLMap } from '@/types/const/serviceEnv';

export const fleAppEnv: FleAppEnv = fleProcess.fleEnv;
type ServerType = 'java';
type BaseURLMap = {
  [K in ServerType]: EnvURLMap;
};
export const baseURLMap: BaseURLMap = {
  java: {
    dev: 'https://dev-gateway.fxqifu.net/pangu',
    qa: 'https://qa-gateway.fxqifu.net/pangu',
    prod: 'https://gateway.fxqifu.net/pangu',
  },
};

type codeType = number;
export const DataCode: Map<string, codeType> = new Map([
  ['SUCCESS', 2000],
  ['LOGIN_ERROR', 100003],
  ['INVALID_LOGIN', 60000013],
]);

export const BaseServiceType = 'java';
export const TokenKey = 'token';
export const BaseURL: string = baseURLMap[BaseServiceType][fleAppEnv] || '';

export type ApplicationType =
  | 'share'
  | 'home'
  | 'help'
  | 'lgjOperate'
  | 'panguOperate'
  | 'cardStore'
  | 'liguanjia'
  | 'peliten'
  | 'csc'
  | 'manager';
type ApplicationUrlMapType = {
  [K in ApplicationType]: EnvURLMap;
};

export const ApplicationUrlMap: ApplicationUrlMapType = {
  // 分享
  share: {
    dev: `https://dev-admin.wowcarp.com/share`,
    qa: `https://qa-admin.wowcarp.com/share`,
    prod: `https://admin.wowcarp.com/share`,
  },
  // 主页
  home: {
    dev: `https://dev-admin.wowcarp.com`,
    qa: `https://qa-admin.wowcarp.com`,
    prod: `https://admin.wowcarp.com`,
  },
  // 帮助中心
  help: {
    dev: 'https://dev-help.wowcarp.com',
    qa: 'https://qa-help.wowcarp.com',
    prod: 'https://help.wowcarp.com',
  },
  // 礼管家运营后台
  lgjOperate: {
    dev: 'http://qa-manager.wowcarp.com', // 没有dev环境
    qa: 'https://qa-manager.wowcarp.com',
    prod: 'https://manager.wowcarp.com',
  },
  // 盘古运营后台
  panguOperate: {
    dev: 'http://dev-yunying.wowcarp.com',
    qa: 'https://qa-yunying.wowcarp.com',
    prod: 'https://yunying.wowcarp.com',
  },
  // 卡券
  cardStore: {
    dev: 'http://qa-cardstore.fxqifu.com',
    qa: 'https://qa-cardstore.fxqifu.com',
    prod: 'https://cardstore.fxqifu.com',
  },
  // 礼管家
  liguanjia: {
    dev: `https://qa-liguanjia.wowcarp.com`,
    qa: `https://qa-liguanjia.wowcarp.com`,
    prod: `https://liguanjia.wowcarp.com`,
  },
  // 百礼腾
  peliten: {
    dev: 'http://dev-www.peliten.com',
    qa: 'https://qa-www.peliten.com',
    prod: 'https://www.peliten.com',
  },
  // 客服
  csc: {
    dev: 'https://dev-admin.wowcarp.com/service',
    qa: 'https://qa-admin.wowcarp.com/service',
    prod: 'https://admin.wowcarp.com/service',
  },
  // 3.0运营后台
  manager: {
    dev: 'https://qa-manager.wowcarp.com',
    qa: 'https://qa-manager.wowcarp.com',
    prod: 'https://manager.wowcarp.com',
  },
};

export const getApplicationUrl = (application: ApplicationType, router = '') => {
  return `${ApplicationUrlMap[application][fleAppEnv]}${router}`;
};
