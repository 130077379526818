export const menus = [
  {
    id: 2051,
    name: '',
    sort: 1,
    permissionKey: 'PFoB3m5y41',
    resourceType: 1,
    description: '',
    path: '/group/wowcarp',
    redirect: '/module/showroom/mall',
    routerType: 'micro',
    hideChildrenInMenu: false,
    hideInMenu: false,
    icon: '',
    target: '_self',
    permission: false,
    props: '',
    pureMenu: false,
    showBreadcrumb: false,
    children: [
      {
        id: 2052,
        name: '商城',
        sort: 1,
        permissionKey: 'X3rE23HazR',
        resourceType: 1,
        description: '',
        path: '/group/mall',
        redirect: '/module/showroom/mall',
        routerType: 'main',
        hideChildrenInMenu: false,
        hideInMenu: true,
        icon: '',
        target: '_self',
        permission: false,
        props: '',
        pureMenu: false,
        showBreadcrumb: false,
        children: [
          {
            id: 2363,
            name: '外部采销商城-商品详情',
            sort: 1,
            permissionKey: 'jpzwnnphe8',
            resourceType: 2,
            description: '',
            path: '/module/showroom/detail/:goodsId',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: false,
            hideInMenu: true,
            icon: '',
            target: '_self',
            permission: false,
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
          {
            id: 2364,
            name: '外部采销商城-购物车',
            sort: 2,
            permissionKey: 'gsxjuf3khi',
            resourceType: 2,
            description: '',
            path: '/module/showroom/cart',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: false,
            hideInMenu: true,
            icon: '',
            target: '_self',
            permission: false,
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
          {
            id: 2365,
            name: '外部采销商城-搜索',
            sort: 3,
            permissionKey: 'ujhnapf4ug',
            resourceType: 2,
            description: '',
            path: '/module/showroom/search',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: false,
            hideInMenu: true,
            icon: '',
            target: '_self',
            permission: false,
            props: '%7B%22singleTab%22%3A%20true%7D',
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
        ],
      },
      {
        id: 2059,
        name: '我的',
        sort: 2,
        permissionKey: 'R9twjAdmnl',
        resourceType: 1,
        description: '',
        path: '/module/showroom/personal',
        redirect: '/module/showroom/orderList',
        routerType: 'micro',
        hideChildrenInMenu: false,
        hideInMenu: false,
        icon: 'icon-wode',
        target: '_self',
        permission: false,
        props: '',
        pureMenu: false,
        showBreadcrumb: false,
        children: [
          {
            id: 2060,
            name: '订单列表',
            sort: 1,
            permissionKey: 'W4campOStm',
            resourceType: 1,
            description: '',
            path: '/module/showroom/orderList',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: true,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [
              {
                id: 2204,
                name: '订单详情',
                sort: 1,
                permissionKey: 'sxtkank18m',
                resourceType: 2,
                description: '',
                path: '/module/showroom/orderList/orderDetail',
                redirect: '',
                routerType: 'micro',
                hideChildrenInMenu: true,
                hideInMenu: true,
                icon: '',
                target: '_self',
                permission: true,
                props: '',
                pureMenu: false,
                showBreadcrumb: false,
                children: [],
              },
              {
                id: 2205,
                name: '销售下单',
                sort: 2,
                permissionKey: 's7oa5bjtwf',
                resourceType: 2,
                description: '',
                path: '/module/showroom/orderList/salesOrder',
                redirect: '',
                routerType: 'micro',
                hideChildrenInMenu: true,
                hideInMenu: true,
                icon: '',
                target: '_self',
                permission: true,
                props: '',
                pureMenu: false,
                showBreadcrumb: false,
                children: [],
              },
            ],
          },
          {
            id: 2065,
            name: '我的发票',
            sort: 2,
            permissionKey: 'fQUtd5RBlS',
            resourceType: 1,
            description: '',
            path: '/module/showroom/invoice',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: true,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
          {
            id: 2068,
            name: '收货地址',
            sort: 3,
            permissionKey: 'oq7Ccj88VX',
            resourceType: 1,
            description: '',
            path: '/module/showroom/receivingAddress',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: true,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
          {
            id: 2070,
            name: '我的方案',
            sort: 4,
            permissionKey: 'XLdBGQwmjH',
            resourceType: 1,
            description: '',
            path: '/module/program/self/0',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: true,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [
              {
                id: 2012,
                name: '编辑个人方案',
                sort: 1,
                permissionKey: 'lxo3eweqyf',
                resourceType: 2,
                description: '',
                path: '/module/program/edit/0/:id',
                redirect: '',
                routerType: 'micro',
                hideChildrenInMenu: true,
                hideInMenu: true,
                icon: '',
                target: '_blank',
                permission: false,
                props: '',
                pureMenu: false,
                showBreadcrumb: false,
                children: [],
              },
              {
                id: 2013,
                name: '新增个人方案',
                sort: 2,
                permissionKey: 'fqbdv3ylkl',
                resourceType: 2,
                description: '',
                path: '/module/program/add/0/:id',
                redirect: '',
                routerType: 'micro',
                hideChildrenInMenu: true,
                hideInMenu: true,
                icon: '',
                target: '_blank',
                permission: false,
                props: '',
                pureMenu: false,
                showBreadcrumb: false,
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const adminMenus = [
  {
    id: 2051,
    name: '',
    sort: 1,
    permissionKey: 'PFoB3m5y41',
    resourceType: 1,
    description: '',
    path: '/group/wowcarp',
    redirect: '/module/showroom/mall',
    routerType: 'micro',
    hideChildrenInMenu: false,
    hideInMenu: false,
    icon: '',
    target: '_self',
    permission: false,
    props: '',
    pureMenu: false,
    showBreadcrumb: false,
    children: [
      {
        id: 2052,
        name: '商城',
        sort: 1,
        permissionKey: 'X3rE23HazR',
        resourceType: 1,
        description: '',
        path: '/group/mall',
        redirect: '/module/showroom/mall',
        routerType: 'main',
        hideChildrenInMenu: false,
        hideInMenu: true,
        icon: '',
        target: '_self',
        permission: false,
        props: '',
        pureMenu: false,
        showBreadcrumb: false,
        children: [
          {
            id: 2363,
            name: '外部采销商城-商品详情',
            sort: 1,
            permissionKey: 'jpzwnnphe8',
            resourceType: 2,
            description: '',
            path: '/module/showroom/detail/:goodsId',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: false,
            hideInMenu: true,
            icon: '',
            target: '_self',
            permission: false,
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
          {
            id: 2364,
            name: '外部采销商城-购物车',
            sort: 2,
            permissionKey: 'gsxjuf3khi',
            resourceType: 2,
            description: '',
            path: '/module/showroom/cart',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: false,
            hideInMenu: true,
            icon: '',
            target: '_self',
            permission: false,
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
          {
            id: 2365,
            name: '外部采销商城-搜索',
            sort: 3,
            permissionKey: 'ujhnapf4ug',
            resourceType: 2,
            description: '',
            path: '/module/showroom/search',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: false,
            hideInMenu: true,
            icon: '',
            target: '_self',
            permission: false,
            props: '%7B%22singleTab%22%3A%20true%7D',
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
        ],
      },
      {
        id: 2059,
        name: '我的',
        sort: 2,
        permissionKey: 'R9twjAdmnl',
        resourceType: 1,
        description: '',
        path: '/module/showroom/personal',
        redirect: '/module/showroom/orderList',
        routerType: 'micro',
        hideChildrenInMenu: false,
        hideInMenu: false,
        icon: 'icon-wode',
        target: '_self',
        permission: false,
        props: '',
        pureMenu: false,
        showBreadcrumb: false,
        children: [
          {
            id: 2060,
            name: '订单列表',
            sort: 1,
            permissionKey: 'W4campOStm',
            resourceType: 1,
            description: '',
            path: '/module/showroom/orderList',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: true,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [
              {
                id: 2204,
                name: '订单详情',
                sort: 1,
                permissionKey: 'sxtkank18m',
                resourceType: 2,
                description: '',
                path: '/module/showroom/orderList/orderDetail',
                redirect: '',
                routerType: 'micro',
                hideChildrenInMenu: true,
                hideInMenu: true,
                icon: '',
                target: '_self',
                permission: true,
                props: '',
                pureMenu: false,
                showBreadcrumb: false,
                children: [],
              },
              {
                id: 2205,
                name: '销售下单',
                sort: 2,
                permissionKey: 's7oa5bjtwf',
                resourceType: 2,
                description: '',
                path: '/module/showroom/orderList/salesOrder',
                redirect: '',
                routerType: 'micro',
                hideChildrenInMenu: true,
                hideInMenu: true,
                icon: '',
                target: '_self',
                permission: true,
                props: '',
                pureMenu: false,
                showBreadcrumb: false,
                children: [],
              },
            ],
          },
          {
            id: 2065,
            name: '我的发票',
            sort: 2,
            permissionKey: 'fQUtd5RBlS',
            resourceType: 1,
            description: '',
            path: '/module/showroom/invoice',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: true,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
          {
            id: 2068,
            name: '收货地址',
            sort: 3,
            permissionKey: 'oq7Ccj88VX',
            resourceType: 1,
            description: '',
            path: '/module/showroom/receivingAddress',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: true,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
          {
            id: 2070,
            name: '我的方案',
            sort: 4,
            permissionKey: 'XLdBGQwmjH',
            resourceType: 1,
            description: '',
            path: '/module/program/self/0',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: true,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [
              {
                id: 2012,
                name: '编辑个人方案',
                sort: 1,
                permissionKey: 'lxo3eweqyf',
                resourceType: 2,
                description: '',
                path: '/module/program/edit/0/:id',
                redirect: '',
                routerType: 'micro',
                hideChildrenInMenu: true,
                hideInMenu: true,
                icon: '',
                target: '_blank',
                permission: false,
                props: '',
                pureMenu: false,
                showBreadcrumb: false,
                children: [],
              },
              {
                id: 2013,
                name: '新增个人方案',
                sort: 2,
                permissionKey: 'fqbdv3ylkl',
                resourceType: 2,
                description: '',
                path: '/module/program/add/0/:id',
                redirect: '',
                routerType: 'micro',
                hideChildrenInMenu: true,
                hideInMenu: true,
                icon: '',
                target: '_blank',
                permission: false,
                props: '',
                pureMenu: false,
                showBreadcrumb: false,
                children: [],
              },
            ],
          },
        ],
      },
      {
        id: 2073,
        name: '配置中心',
        sort: 3,
        permissionKey: '2kEQNyM1dv',
        resourceType: 1,
        description: '',
        path: '/module/settingCenter',
        redirect: '/module/workbench/accountManagement',
        routerType: 'micro',
        hideChildrenInMenu: false,
        hideInMenu: false,
        icon: 'icon-peizhizhongxin',
        target: '_self',
        permission: false,
        props: '',
        pureMenu: false,
        showBreadcrumb: false,
        children: [
          {
            id: 2074,
            name: '账户管理',
            sort: 1,
            permissionKey: '14k8E3nPGJ',
            resourceType: 1,
            description: '',
            path: '/module/workbench/accountManagement',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: true,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
          {
            id: 2077,
            name: '商品交易明细',
            sort: 2,
            permissionKey: '2nf1ura3av',
            resourceType: 2,
            description: '',
            path: '/module/workbench/transaction',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: false,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
          {
            id: 2078,
            name: '员工管理',
            sort: 3,
            permissionKey: '1mmvsnlgrb',
            resourceType: 2,
            description: '',
            path: '/module/workbench/employees/list',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: false,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
          {
            id: 2079,
            name: '支付短信设置',
            sort: 4,
            permissionKey: 'Co32lAUF8A',
            resourceType: 1,
            description: '',
            path: '/module/showroom/MessagePhone',
            redirect: '',
            routerType: 'micro',
            hideChildrenInMenu: true,
            hideInMenu: false,
            icon: '',
            target: '_self',
            permission: false,
            props: '',
            pureMenu: false,
            showBreadcrumb: false,
            children: [],
          },
        ],
      },
    ],
  },
];
