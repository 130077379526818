import Cookies from 'js-cookie';
type actionType = 'csc' | 'message' | 'importAndExport' | 'vetting';
export interface ApplicationItemType {
  systemId: string;
  hrefLogo: string;
  logo: string;
  textLogo: string;
  title: string;
  description?: string;
  dashboard: string;
  actionsIcon: actionType[];
}
const loginType = Cookies.get('loginType') || 190;
const pageInfoLogintype: any = {
  90: {
    systemId: '100001',
    title: '智鲤',
    logo: 'https://oss.elebuys.com/resource/goods/materials/202404091541470001243490.png',
    textLogo: 'https://oss.elebuys.com/resource/goods/materials/202404091541190001243488.png',
    description: '企业数智化解决方案',
    dashboard: '/module/workbench/dashboard', // 首页
    actionsIcon: ['csc', 'importAndExport', 'vetting', 'message', 'carpdance'],
  },
  180: {
    systemId: '100001',
    title: ' ',
    logo: ' ',
    textLogo: ' ',
    description: ' ',
    actionsIcon: ['csc', 'importAndExport', 'vetting', 'message', 'carpdance'],
  },
  190: {
    systemId: '100008',
    title: ' ',
    logo: ' ',
    textLogo: ' ',
    description: ' ',
    actionsIcon: ['csc', 'importAndExport', 'vetting', 'message', 'carpdance'],
  },
  200: {
    systemId: '100009',
    title: '智鲤',
    logo: 'https://oss.elebuys.com/resource/goods/materials/202404091541470001243490.png',
    textLogo: 'https://oss.elebuys.com/resource/goods/materials/202404091541190001243488.png',
    description: '企业数智化解决方案',
    dashboard: '/module/workbench/dashboard', // 首页
    actionsIcon: ['csc', 'importAndExport', 'vetting', 'message', 'carpdance'],
  },
};
export const ApplicationInfo: ApplicationItemType = pageInfoLogintype[loginType];
export const hrefLogos = () => {
  const login = window.location.origin;
  if (login.includes('dist')) {
    return '//oss.elebuys.com/logo/wowcarp/wowcarp-logo.png';
  } else {
    return '//oss.elebuys.com/resource/goods/materials/202404091541470001243490.png';
  }
};
