import React, { useState } from 'react';
import classnames from 'classnames';
import { Input, Badge, Carousel, Image } from 'antd';
import { Icon } from '@/components';
import { Scrollbars } from 'react-custom-scrollbars';

import './index.less';

interface MainProps {
  [key: string]: any;
}

// 页脚
const ClassA: React.FC<MainProps> = (props): JSX.Element => {
  const { data, id, handleClickComponent, classNames, componentClose } = props;
  const { bgColor, marginBottom, rightImageList, carouselList, classList } = data;

  const handleClassClick = (id: string, e: any) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    // window.open(`${routerPrefix}/showroom/search?keyword=${searchKeyword}&flagCategoryTree=${id}`);
  };

  return (
    <div
      className={classnames({
        ...classNames,
        'classA-component': true,
        'zl-footer-container': true,
      })}
      id={id}
      style={{
        backgroundColor: bgColor || '#262626',
        paddingBottom: 16,
        marginBottom,
      }}
      onClick={() => {
        handleClickComponent(id);
      }}
    >
      <div className="zl-footer-box">
        <div>
          <div className="zl-info-title">{data?.corporateImageTextList?.[0]?.nameValue}</div>
          <div className="zl-info-text">{data?.corporateImageTextList?.[0]?.sloganValue}</div>
          <div className="zl-contactInfo-image">
            {data?.contactInformationList?.map((item: any, index: number) => {
              return (
                <div className="zl-image-container" style={{ marginLeft: index === 1 ? 12 : 0 }}>
                  {item?.qrCode && (
                    <Image src={item?.qrCode} preview={false} width={72} height={72} />
                  )}
                  <span className="zl-contact-info-title">{item?.nameValue}</span>
                </div>
              );
            })}
          </div>
        </div>
        {data?.relatedInfoList?.map((item: any) => {
          return (
            <div>
              <div className="zl-info-title">{item?.title}</div>
              {item?.infoList?.map((info: any) => {
                return (
                  <div
                    className="zl-info-text"
                    onClick={() => {
                      window.open(item?.link);
                    }}
                  >
                    {info?.name}
                  </div>
                );
              })}
            </div>
          );
        })}
        <div>
          <div className="zl-info-title">联系我们</div>
          <div className="zl-info-text">地址：{data?.contactTextList?.[0]?.address}</div>
          <div className="zl-info-text">服务热线：{data?.contactTextList?.[0]?.serviceHotline}</div>
          <div className="zl-info-text">
            商务合作：{data?.contactTextList?.[0]?.businessCooperation}
          </div>
          <div className="zl-info-text">加入我们：{data?.contactTextList?.[0]?.recruit}</div>
        </div>
      </div>
      {componentClose}
      {/* <div className="zl-bottom-text">
        ©2024 浙江xxx品牌管理有限公司 All Right Reserved{' '}
        <span style={{ marginLeft: 16 }}>浙ICP备2021009972号-2</span>
      </div>
      <div className="zl-bottom-text">
        ©2024 浙江xxx品牌管理有限公司 All Right Reserved{' '}
        <span style={{ marginLeft: 16 }}>浙ICP备2021009972号-2</span>
      </div> */}
      <div />
    </div>
  );
};

export default ClassA;
