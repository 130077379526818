import { makeAutoObservable, configure } from 'mobx';
import type { CommonTypes } from '@/types/stores/common.interface';
import { fitment } from '@/api';
import httpClient from '@/tools/httpClient';
import type { FleAppEnv } from '@/types/const/serviceEnv';

export const fleAppEnv: FleAppEnv = fleProcess.fleEnv || 'dev';

configure({ enforceActions: 'observed' });

class FitmentStore implements CommonTypes {
  [key: string]: any;

  loading = false;
  accountInfo = { loginLink: 'fle' };
  companyConfig = {};
  employList: Array<{ name: string; id: number | string }> = [];
  outMallKey = '';

  constructor() {
    makeAutoObservable(this);
  }

  // 获取内部运营页数据
  getInsidePageInfo = async (params: any = {}) => {
    const data = await httpClient.post(fitment.get('getPageInfo'), params);
    if (data.success) {
      return data;
    }
  };
  // 获取运营页数据
  getPageInfo = async (params: any = {}) => {
    const data = await httpClient.get(fitment.get('userPageInfo'), { params });
    if (data.success) {
      return data;
    }
  };

  // 获取首页id
  getHomeIndexId = async (params: any = {}) => {
    const data = await httpClient.post(fitment.get('getHomeIndexId'), params);
    if (data.success) {
      return data;
    }
  };
  // 获取外部商城详情id
  getOutHomeIndexId = async (params: any = {}) => {
    let origin = null;
    const isLocalHost = window.location.origin.includes('https://localhost');
    if (window.location.origin.includes('https://')) {
      origin = window.location.origin.split('https://').join('');
    }
    if (window.location.origin.includes('http://') && !isLocalHost) {
      origin = window.location.origin.split('http://').join('');
    }
    if (isLocalHost) {
      origin = 'dev-mall.wowcarp.com';
    }
    const data = await httpClient.post(fitment.get('getOutHomeIndexId'), {
      ...params,
      domain: origin,
    });
    if (data.success) {
      return data;
    }
  };
}

export default new FitmentStore();
